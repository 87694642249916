.fc-event {
  cursor: pointer;
  border-radius: 12px;
}

.fc-daygrid-day-events {
  padding-left: 4px;
  padding-right: 4px;
}

.fc-daygrid-day-number {
  font-size: 22px;
  font-weight: bold;
}

.event-modal-width {
  max-width: 90%;
}