.list-group-item {
  background-color: rgba(255, 255, 255, 0);
  border: 1px solid $border;
  padding: 1rem 1.5rem;
  border-radius: 0;
  @at-root [data-theme-version="dark"] & {
    border-color: $d-border;
  }
  &:first-child {
    border-top-left-radius: 0;
    border-top-right-radius: 0;
  }
  &:last-child {
    border-bottom-left-radius: 0;
    border-bottom-right-radius: 0;
  }
}

.list-group-item.disabled,
.list-group-item:disabled {
  color: #fff;
  background-color: $secondary;
  border-color: $secondary;
}
[class*="bg-"] {
  .list-group-item {
    border-color: rgba($white, 0.05);
  }
}
.bg-warning {
  .list-group-item {
    border-color: rgba($black, 0.05);
  }
}
// .list-group-item-action {
//     color: #fff;
// }
