[data-theme-version="dark"] {
  .nav-header,
  .nav-control {
    background-color: $d-bg;
  }

  .nav-control {
    color: $white;
  }

  .brand-logo {
    color: $white;

    &:hover {
      color: $white;
    }
  }
}
