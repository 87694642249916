@import "../../abstracts/_mixin";
@import "../../abstracts/_variable";

@include custommq($min: 768px) {
  [data-sidebar-style="mini"] {
    .nav-header {
      width: 5rem;

      .nav-control {
        z-index: -1;
        .hamburger {
          left: 5rem !important;
          .line {
            background-color: $dark !important;
          }
        }
      }
      .brand-title {
        display: none;
      }
      .hamburger {
        display: none;
      }
    }
    .header .header-content {
      padding-left: 1.875rem;
      @at-root [direction="rtl"]#{&} {
        padding-right: 1.875rem;
      }
    }
    .deznav {
      width: 5rem;
      overflow: visible;
      position: absolute !important;
      .copyright,
      .add-menu-sidebar {
        display: none;
      }
      .nav-text {
        display: none;
      }

      .slimScrollDiv,
      .deznav-scroll {
        overflow: visible !important;
      }
      .nav-user {
        padding: 11px;
        .media-body {
          display: none;
        }
      }

      .metismenu {
        li {
          a {
            padding: 0.813rem 0.875rem;
            svg {
              margin-right: 0;
            }
          }

          & > ul {
            position: absolute;
            left: 5rem;
            top: 2.9375rem;
            width: 11.875rem;
            z-index: 1001;
            display: none;
            padding-left: 1px;
            box-shadow: 0px 0px 40px 0px rgba(82, 63, 105, 0.1);
            height: auto !important;
            border-radius: 6px;
            background: #fff;

            @at-root [direction="rtl"]:not([data-layout="horizontal"])#{&} {
              left: auto;
              right: 5rem;
              box-shadow: -6px 6px 10px rgba(0, 0, 0, 0.15);
            }
          }
        }
        & > li {
          padding: 2px 15px;
          & > a {
            &.has-arrow {
              &:after {
                display: none;
              }
            }
          }
        }
        .nav-label,
        .nav-badge {
          display: none;
        }
      }
    }

    .content-body {
      margin-left: 5rem;
    }

    .footer {
      padding-left: 5rem;
    }
  }

  [data-sidebar-style="mini"][data-layout="vertical"] {
    .deznav {
      .metismenu {
        li {
          &:hover > ul {
            display: block;
          }
        }

        & > li {
          &:nth-last-child(-n + 1) {
            & > ul {
              bottom: 0;
              top: auto !important;
              &:after {
                top: auto;
                bottom: 20px;
              }
            }
          }

          @include respond("tab-land") {
            &:nth-last-child(-n + 1) {
              & > ul {
                bottom: 0;
                top: auto !important;
              }
            }
          }

          & > ul {
            overflow: visible;

            &:after {
              content: none;
            }

            li:hover {
              ul {
                padding: 10px 0;
                width: 13rem;
                left: 13rem;
                top: -10px;
                border: 0;
                margin: 0;
                &:after {
                  content: none;
                }
                @at-root [direction="rtl"]#{&} {
                  left: auto;
                  right: 13rem;
                }
              }
            }
          }
          &.mm-active {
            & > a {
              background: $primary;
              color: $white;
              border-radius: $radius;
              @at-root [data-theme-version="dark"]#{&} {
                background: $dark-card;
              }
            }
          }
          &:hover {
            & > a {
              background: $primary;
              color: $white;
              border-radius: $radius;
              position: unset;

              @at-root [data-theme-version="dark"]#{&} {
                background: $dark-card;
              }

              .nav-text {
                @at-root [direction="rtl"]#{&} {
                  padding-left: auto;
                  padding-right: 1.6875rem;
                }
              }
            }
            & > ul {
              height: auto !important;
              overflow: visible;
              border: 0;
              margin-left: 0;
              left: 5rem;
              width: 13rem;
              border-radius: 12px;
              border: 0;
              padding: 10px 0;
              top: 0;

              &:after {
                content: "";
                position: absolute;
                background: inherit;
                width: 10px;
                height: 10px;
                transform: rotate(45deg);
                -webkit-transform: rotate(45deg);
                -ms-transform: rotate(45deg);
                -moz-transform: rotate(45deg);
                left: -5px;
                top: 20px;
              }

              @at-root [data-theme-version="dark"]#{&} {
                box-shadow: 0px 9px 10px 0px rgba(0, 0, 0, 0.1);
              }
              @at-root [direction="rtl"]#{&} {
                left: auto;
                right: 5rem;
              }
              a {
                padding: 6px 20px 6px 20px;

                &:before {
                  content: none;
                }
                &.has-arrow:after {
                  right: 1.25rem;
                }
              }

              ul {
                a {
                  padding: 6px 20px 6px 20px;
                  margin-left: -1.6px;
                  &:before {
                    content: none;
                  }
                }
              }
            }
          }
        }
      }
    }
  }

  [data-sidebar-style="mini"][data-header-position="fixed"][data-container="boxed"][data-layout="vertical"] {
    .header {
      width: 1199px;
    }
  }

  [data-sidebar-style="mini"][data-layout="horizontal"] {
    .deznav .metismenu {
      padding: 0 30px;
    }
    .deznav .metismenu > li > a {
      padding: 15px 20px;
      @at-root [direction="rtl"]#{&} {
        padding: 15px 20px;
        svg {
          margin-left: 0;
        }
      }
      svg {
        margin-right: 0;
        margin-top: 0;
      }
    }
  }
}

@include respond("tab-land") {
  [data-sidebar-style="mini"] {
    .deznav {
      li {
        &.mm-active {
          ul {
            height: auto !important;
          }
        }

        a.has-arrow {
          &::after {
            transform: rotate(-45deg) translateY(-50%);
          }
        }
      }
    }
  }
}
