.card {
  margin-bottom: 1.875rem;
  background-color: $white;
  transition: all 0.5s ease-in-out;
  position: relative;
  border: 0px solid transparent;
  border-radius: $radius;
  box-shadow: 0px 12px 23px 0px rgba($secondary, 0.04);
  height: calc(100% - 30px);

  // z-index: 0;
  @include custommq($max: 575px) {
    margin-bottom: 0.938rem;
    height: calc(100% - 0.938rem);
  }

  &-body {
    padding: 1.875rem;
    @include respond("phone") {
      padding: 1rem;
    }
  }

  &-title {
    font-size: 20px;
    font-weight: 500;
    // line-height: 2.2rem;
    color: #000;
    text-transform: capitalize;
    // margin-bottom: 1.5rem;

    &--large {
      font-size: 1.5rem;
    }

    &--medium {
      font-size: 1rem;
    }

    &--small {
      font-size: 0.875rem;
    }
  }

  &-header {
    border-color: $border;
    position: relative;
    background: transparent;
    padding: 1.5rem 1.875rem 1.25rem;
    display: flex;
    justify-content: space-between;
    align-items: center;
    // min-height: 65px;
    @include respond("phone") {
      padding: 1.25rem 1rem 1.25rem;
    }
    @at-root [data-theme-version="dark"] & {
      border-color: $d-border;
    }

    .card-title {
      margin-bottom: 0px;
    }
    .subtitle {
      padding-top: 5px;
      font-size: 14px;
      line-height: 1.5;
    }
  }

  &-footer {
    border-color: $border;
    background: transparent;
    padding: 1.25rem 1.875rem 1.25rem;
    @at-root [data-theme-version="dark"] & {
      border-color: $d-border;
    }
    @include respond("phone") {
      padding: 1rem;
    }
  }
}

.transparent-card {
  &.card {
    background: transparent;
    border: 1px solid transparent;
    box-shadow: none;
  }
}

///////////////////
//  Card action
///////////////////
.card-action {
  > a {
    display: inline-block;
    width: 30px;
    height: 30px;
    line-height: 30px;
    border-radius: 5px;
    border-color: transparent;
    text-align: center;
    background: darken($primary, 57%);
    color: $white;
    margin-right: 8px;

    @at-root [data-theme-version="dark"] & {
      background: $d-bg;
    }

    &:last-child {
      margin-right: 0;
    }
    &:hover,
    &:focus {
      background: darken($primary, 57%);

      @at-root [data-theme-version="dark"] & {
        background: $d-bg;
      }
    }

    i,
    span {
      font-size: 1rem;
      // color: #333333;
    }
    // padding: 0 .5rem;
  }

  .dropdown {
    width: 30px;
    height: 30px;
    border-radius: 5px;
    border-color: transparent;
    text-align: center;
    margin-right: 8px;
    top: -2px;
    position: relative;
    display: inline-block;
    background: darken($primary, 57%);
    color: $primary;

    @at-root [data-theme-version="dark"] & {
      background: $d-bg;
    }

    &:hover,
    &:focus {
      background: darken($primary, 57%);

      @at-root [data-theme-version="dark"] & {
        background: $d-bg;
      }
    }

    .btn {
      padding: 0;
      line-height: 27px;
      color: $white;

      &:focus {
        box-shadow: none;
      }
    }
  }
}

.card-fullscreen {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 99999999;
  overflow: auto;
}

.card-loader {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  background-color: rgba(0, 0, 0, 0.75);
  z-index: 999;
}

.card-loader i {
  margin: 0 auto;
  background: darken($primary, 7%);
  padding: 10px;
  border-radius: 50%;
  color: $white;
  font-size: 1rem;
}

.rotate-refresh {
  -webkit-animation: mymove 0.8s infinite linear;
  animation: mymove 0.8s infinite linear;
  display: inline-block;
}

.card-header {
  .date_picker {
    display: inline-block;
    padding: 8px;
    border: 1px solid $border;
    cursor: pointer;
    border-radius: 0.375rem;
  }
  .border-0 {
    padding-bottom: 0;
  }
}

@-webkit-keyframes mymove {
  0% {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}

@keyframes mymove {
  0% {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}
