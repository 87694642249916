.blockUI.blockMsg.blockPage {
  border: 0 !important;
}

#loginForm {
  cursor: auto;
}

.blockMsg {
  border: 0px !important;
  width: 20% !important;
  h1 {
    font-size: 16px;
    padding: 8px 0;
    margin-bottom: 0;
  }
}
