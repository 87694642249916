// Variable.scss

.nestable-cart {
  overflow: hidden;
}

// .dd-item > button {
//     height: 2.1rem;
// }
.dd-handle {
  color: $white;
  background: $primary;
  border-radius: 5px;
  padding: 8px 15px;
  height: auto;
  border: 1px solid $border;
}
.dd-handle:hover {
  color: $white;
  background: $primary;
}
// #nestable2 .dd-handle {
// color: $white;
// border: 1px solid $border;
// background: $primary;
// }
// #nestable2 .dd-handle:hover {
//     background: $primary;
// }
// #nestable2 .dd-item>button:before {
//     color: $white;
// }
.dd3-content:hover {
  color: $white;
  background: $primary;
}
.dd3-content {
  color: $white;
}
.dd-item > button {
  line-height: 28px;
  color: $white;
}
.nestable .icon-minus-gray,
.nestable .icon-plus-gray {
  background-image: none;
}
.nestable .nestable-icon:before {
  content: "\f068";
  font-family: FontAwesome;
  width: auto;
  height: auto;
  display: contents;
}
.nestable .nestable-item--children-collapsed .nestable-icon:before {
  content: "\f067";
  font-family: FontAwesome;
  width: auto;
  height: auto;
  display: contents;
}
