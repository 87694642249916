@import "../../abstracts/variable";

////////////////////
// Horizontal Nav
////////////////////
@media (min-width: 1199px) {
  [data-layout="horizontal"] {
    .nav-header {
      width: 16rem;

      .nav-control {
        display: none;
      }

      .brand-logo {
        /*  padding-left: 0;
                justify-content: center; */
      }
    }

    .header {
      width: 100%;
      padding-left: 16rem;
    }

    .deznav {
      width: 100%;
      position: relative;
      height: auto;
      padding-bottom: 0;
      top: 0;
      z-index: 2;

      .slimScrollDiv {
        overflow: visible !important;

        .deznav-scroll {
          overflow: visible !important;
        }
      }
      .deznav-scroll {
        overflow: visible !important;
      }
      .slimScrollBar {
        display: none !important;
      }

      .nav-user,
      .nav-label {
        display: none;
      }

      .metismenu {
        flex-direction: row;

        .collapse.in {
          display: none;
        }
        ul {
          border-left: 0;
          @at-root [data-theme-version="dark"]#{&} {
            box-shadow: 0px 10px 13px 0px rgba(0, 0, 0, 0.1);
          }
        }
        li {
          flex-direction: column;
          position: relative;

          &:hover {
            & > ul {
              display: block;
            }
          }

          & > ul {
            position: absolute;
            height: auto !important;
            top: 3.25rem;
            width: 100%;
            min-width: 13.75rem;
            z-index: 999;
            left: auto;
            right: auto;
            padding: 0.5rem 0;
            display: none;
            box-shadow: 0px 0px 40px 0px rgba(82, 63, 105, 0.1);
            margin: 0;
            background: $white;

            @at-root [data-theme-version="dark"]#{&} {
              box-shadow: 0px 10px 13px 0px rgba(0, 0, 0, 0.1);
            }

            li {
              padding: 0;

              a {
                transition: all 0.4s ease-in-out;
                padding: 8px 20px 8px 45px;
                margin-left: -0.1rem;
                @at-root [direction="rtl"]#{&} {
                  padding: 8px 45px 8px 20px;
                  text-align: right;
                }
                &:hover {
                  border-radius: 0.4rem;
                }
                &:before {
                  left: 22px;
                  @at-root [direction="rtl"]#{&} {
                    left: auto;
                    right: 21px;
                  }
                }
              }
            }

            ul {
              left: 100%;
              top: 0;
              box-shadow: 0px 10px 13px 0px rgba(82, 63, 105, 0.05);
              @at-root [direction="rtl"]#{&} {
                left: auto;
                right: 100%;
              }
            }
          }
        }

        & > li {
          flex: 0 0 auto;
          position: relative;

          @at-root [data-theme-version="dark"]#{&} {
            border-color: rgba(255, 255, 255, 0.07);

            &.mm-active {
              border-color: transparent;
            }
          }
          &.mm-active {
            padding: 0;
            & > a {
              background: #fff;
              box-shadow: 0px 0px 13px 0px rgba(82, 63, 105, 0.05);
            }
          }
          @at-root [direction="rtl"]#{&} {
            //&:not(:first-child) {
            //    border-right: lighten($color: $color-pallate-2, $amount: 10%);
            //}

            &:first-child {
              border-right: 0;
            }

            @at-root [data-theme-version="dark"]#{&} {
              border-color: $d-border;
            }
          }
          & > a {
            padding: 15px 40px 15px 15px;
            @at-root [direction="rtl"]#{&} {
              padding: 15px 15px 15px 40px;
            }

            i {
              padding: 0 0.4375rem 0 0;

              @at-root [direction="rtl"]#{&} {
                padding: 0 0 0 0.4375rem;
              }
            }
            .nav-badge {
              display: none;
            }
            &:after {
              right: 20px;
              transform: rotate(-135deg) translateY(-50%);
            }
          }
          &:hover {
            border-color: transparent;

            & > ul {
              display: flex !important;
              flex-direction: column;
              flex-wrap: wrap;
              height: auto !important;
              box-shadow: 0px 10px 13px 0px rgba(82, 63, 105, 0.05);
              @at-root [data-theme-version="dark"]#{&} {
                box-shadow: 0px 0 13px 0px rgba(0, 0, 0, 0.1);
              }
            }
          }
          & > ul {
            & > li {
              &:hover {
                ul.collapse {
                  display: block !important;
                  position: absolute;
                  left: auto !important;
                  right: -100% !important;
                  top: 0 !important;
                }
              }
            }
          }
          &:nth-last-child(-n + 5) {
            & > ul {
              left: auto;
              right: 0;
              & > li {
                &:hover {
                  ul.collapse {
                    right: auto !important;
                    left: -100% !important;
                  }
                }
              }
            }
          }
          &:last-child {
            & > ul {
              ul {
                left: -100%;
              }
            }
          }
          @at-root [direction="rtl"]#{&} {
            &:nth-last-child(-n + 3) {
              & > ul {
                left: 0;
                right: auto;
                & > li {
                  &:hover {
                    ul.collapse {
                      right: -100% !important;
                      left: auto !important;
                    }
                  }
                }
              }
            }
          }
        }
      }
    }

    .content-body {
      margin-left: 0;
      .page-titles {
        margin-left: 0;
        margin-right: 0;
        margin-bottom: 1.875rem;
      }
    }

    .footer {
      margin-left: 0;
      margin: 0 auto;
      padding-left: 0;
    }

    .sidebar-right {
      z-index: 1;
    }
  }

  [data-header-position="fixed"][data-layout="horizontal"] {
    .deznav {
      top: 5rem;
    }
  } //ok

  [data-header-position="fixed"][data-sidebar-position="fixed"] {
    .deznav {
      position: fixed;
    }
  } //ok

  [data-header-position="fixed"][data-layout="horizontal"][data-sidebar-position="fixed"] {
    .content-body {
      padding-top: 8.3rem;
    }
  } //ok

  [data-layout="horizontal"][data-container="boxed"] {
    .footer {
      margin-left: 0;
      max-width: 1199px;
      margin: 0 auto;
    }
  }

  [data-layout="horizontal"][data-container="wide"] {
    .page-titles {
      margin-left: -30px;
      margin-right: -30px;
    }
  }

  [data-layout="horizontal"][data-sidebar-style="compact"] {
    .page-titles {
      margin-top: 0;
    }

    .deznav {
      .metismenu {
        & > li {
          & > ul {
            top: 4.315rem;
          }
          & > a {
            padding: 0.8125rem 2.1rem;

            &::after {
              display: none;
            }
          }

          li {
            text-align: left;
          }
        }
      }
    }
  }

  [data-sidebar-style="mini"][data-layout="horizontal"] {
    .nav-header {
      width: 6rem;
      padding-left: 30px;
      padding-right: 30px;
      .brand-logo {
        justify-content: start;
      }
    }

    .header {
      width: 100%;
      padding-left: 6rem;
    }

    .metismenu {
      & > li {
        &:hover {
          a {
            width: auto;
            .nav-text {
              display: none;
            }
          }
        }
      }
    }
  }

  [data-header-position="fixed"][data-layout="horizontal"][data-sidebar-position="fixed"][data-sidebar-style="compact"] {
    .content-body {
      padding-top: 8.75rem;
    }
  }

  [data-sidebar-position="fixed"][data-layout="horizontal"] {
    .deznav.fixed {
      position: fixed;
      left: 0;
      top: 0;
    }
  }
}
