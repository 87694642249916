[data-theme-version="dark"] {
  .sidebar-right {
    background-color: lighten($d-bg, 3.2%);

    .nav-tabs {
      background-color: lighten($d-bg, 3.2%);
      border-bottom: 1px solid $d-bg;

      .nav-link {
        &.active {
          background-color: $d-bg;
        }
      }
    }
  }
}
