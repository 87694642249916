.review-box {
  padding: 30px 30px 10px 30px;
  border-bottom: 1px solid $border-color;
  .media-footer {
    min-width: 150px;
  }
  h4 {
    font-size: 20px;
  }
  p {
    font-size: 16px;
    line-height: 1.5;
  }
  .star-review {
    span {
      display: block;
      font-size: 30px;
      font-weight: 600;
      margin-bottom: 15px;
      line-height: 1.3;
    }
    i {
      font-size: 18px;
      margin: 0 2px;
    }
  }
  @include respond("laptop") {
    .media-footer {
      min-width: 110px;
    }
    .star-review {
      i {
        font-size: 16px;
        margin: 0 1px;
      }
    }
  }
  @include respond("phone-land") {
    display: block !important;
    img {
      width: 60px;
      float: left;
    }
    .star-review {
      margin-top: 15px;
      span {
        font-size: 16px;
        display: inline-block;
        margin-bottom: 0;
      }
    }
  }
  @include respond("phone") {
    p {
      font-size: 14px;
    }
    h4 {
      font-size: 16px;
    }
  }
}
.testimonial-one {
  .media {
    img {
      max-width: 50px;
    }
  }
}

.testimonial-one-navigation {
  span {
    cursor: pointer;
  }
}
